.para-container {
  padding: 100px 150px 100px 150px;
}
.para {
  text-align: center;
}
.paras{
  margin-top: 20px;

  font-size: 23px;
  font-weight: 500;

  line-height: 1.5;

  color: #000000;
  
} 
@media (max-width: 1025px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .para-container {
    padding: 100px 50px 100px 50px;
  }
}
@media (max-width: 924px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .para-container {
    padding: 100px 50px 100px 50px;
  }
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .para-container {
    padding: 30px 30px 30px 30px;
  }
  .para p {
    margin-top: 400px;

    font-size: 18px;
    font-weight: 500;

    line-height: 1.5;

    color: #000000;
  }
  .paras {
    margin-top: 412px;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;
}
}
