.herocounter-main {
  width: 100%;
  background-color: #273253;

  margin-top: -180px;
  z-index: 10;
  position: absolute;
}
.hero-counter-container {
  padding: 30px 150px 30px 150px;
}
.counter {
  display: flex;
  justify-content: space-evenly;

  gap: 20px;
}
.counter-1 h1 {
  font-size: 4rem;
  font-weight: 900;
  color: #faed3d;
  text-align: center;
}
.counter-1 p {
  font-size: 20px;
  color: #faed3d;
  text-align: center;
}
.line {
  height: 100px;
  width: 1px;
  background-color: #fff;
}
@media (max-width: 924px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-counter-container {
    padding: 30px 50px 30px 50px;
  }
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-counter-container {
    padding: 30px 30px 30px 30px;
  }
  .counter {
    display: flex;
    justify-content: space-evenly;
    gap: 70px;
    flex-direction: column;
  }
  .line {
    display: none;
  }
  .herocounter-main {
    width: 100%;
    background-color: #273253;
    margin-top: -45px;
    z-index: 10;
    position: absolute;
  }
}
