*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



.nav-bar {
  width: 100%;
  display: flex;
  justify-content:center ;
  align-items: center;
  list-style: none;
  position: relative;
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}
.logo img {width: 40px;}
.menu {display: flex;}
.menu li {padding-left: 30px;}
.menu li a {
  display: inline-block;
  text-decoration: none;
  color: var(--color1);
  text-align: center;
  transition: 0.15s ease-in-out;
  position: relative;
  
  
  font-size: 18px;
}
.menu li a::after {
  content: "";
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--color1);
  transition: 0.30s ease-in-out;
}
.menu li a:hover:after {width: 100%;}
.open-menu , .close-menu {
  /* position: absolute; */
  color: var(--color1);
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
}
.open-menu {
  top: 50%;
  right: 20px;

}
.close-menu {
  top: 20px;
  right: 20px;
}
/* .menu{
  padding-left: 250px;
  display: flex;
  align-items: center;
} */


/* CSS */
.button-1 {
  background-color:#273253 ;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;

  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-header{
  padding-left: 30px;
}
#home{
  font-weight: 600;
  color: #273253;
}


#check {display: none;}
@media(max-width: 1024px){
  .menu {
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 100;
    background-color: #273253;
    transition: all 0.4s ease-in-out;
}
.menu li a {
  display: inline-block;
  text-decoration: none;
  color: white;
  text-align: center;
  transition: 0.15s ease-in-out;
  position: relative;
  font-size: 18px;
}
.nav-bar {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
position: relative;
background-color: white;
padding: 20px 30px;
box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}
.fa-solid, .fas {
font-weight: 900;
font-size: 25px;
color: #ffffff;
}
.x-mark{
padding: 20px 30px;
}

.fa-solid, .fas {
font-weight: 900;
font-size: 25px;
color: #273253;
}
.fa-bars{
color: #273253;

}
.fa-times{
color: #FFFFFF;
}


.menu li {margin-top: 40px;}
.menu li a {padding: 10px;}
.open-menu , .close-menu {display: block;}
#check:checked ~ .menu {left: 0;}
}
@media(max-width: 968px){
  .menu {
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 100;
    background-color: #273253;
    transition: all 0.4s ease-in-out;
}
.menu li a {
  display: inline-block;
  text-decoration: none;
  color: white;
  text-align: center;
  transition: 0.15s ease-in-out;
  position: relative;
  font-size: 18px;
}
.nav-bar {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
position: relative;
background-color: white;
padding: 20px 30px;
box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}
.fa-solid, .fas {
font-weight: 900;
font-size: 25px;
color: #ffffff;
}
.x-mark{
padding: 20px 30px;
}

.fa-solid, .fas {
font-weight: 900;
font-size: 25px;
color: #273253;
}
.fa-bars{
color: #273253;

}
.fa-times{
color: #FFFFFF;
}


.menu li {margin-top: 40px;}
.menu li a {padding: 10px;}
.open-menu , .close-menu {display: block;}
#check:checked ~ .menu {left: 0;}
}



@media(max-width: 640px){
  .menu {
      flex-direction: column;
      /* align-items: center;
      justify-content: center; */
      width: 80%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 100;
      background-color: #273253;
      transition: all 0.4s ease-in-out;
  }
  .menu li a {
    display: inline-block;
    text-decoration: none;
    color: white;
    text-align: center;
    transition: 0.15s ease-in-out;
    position: relative;
    font-size: 18px;
}
.nav-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  position: relative;
  background-color: white;
  padding: 20px 30px;
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}
.fa-solid, .fas {
  font-weight: 900;
  font-size: 25px;
  color: #ffffff;
}
.x-mark{
  padding: 20px 30px;
}

.fa-solid, .fas {
  font-weight: 900;
  font-size: 25px;
  color: #273253;
}
.fa-bars{
  color: #273253;

}
.fa-times{
  color: #FFFFFF;
}

  .menu li {margin-top: 40px;}
  .menu li a {padding: 10px;}
  .open-menu , .close-menu {display: block;}
  #check:checked ~ .menu {left: 0;}
}
