.course-main {
  background-color: #f7fdff;
}
.course-container {
  padding: 100px 150px 100px 150px;
}
.course-content {
  text-align: center;
  display: grid;
}
.course-content h1 {
  font-size: 6rem;
  line-height: 1.1;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  color: #273253;
  letter-spacing: 1px;
}
.course-content p {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #4d4d4d;
  margin-top: 20px;
}
.course-card-main {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 100px;
}
.course-card {
  /* height: 470px;
    width: 400px; */
  border-radius: 20px;
  padding-bottom: 30px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
.course-card img {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  border: 1px 1px 0px 0px solid #4d4d4d;
}
.course-card-content {
  margin-top: 20px;
  text-align: center;
  display: grid;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
}
.course-card-content p {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 500;
}
.course-card-content h1 {
  text-align: center;
  margin-top: 10px;
  font-size: 2.5rem;
  color: #000000;
}
@media (max-width: 1025px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .course-container {
    padding: 100px 50px 100px 50px;
  }
}
@media (max-width: 924px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .course-card-main {
    display: grid;
    justify-content: space-between;
    gap: 30px;
    margin-top: 100px;
    grid-template-columns: auto auto;
  }
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .course-container {
    padding: 0px 30px 30px 30px;
  }
  .course-content h1 {
    font-size: 4rem;
    line-height: 1.1;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    margin-top: 20px;
    color: #273253;
    letter-spacing: 1px;
  }
  .course-content p {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #4d4d4d;
    margin-top: 20px;
  }
  .course-card-main {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    margin-top: 50px;
    flex-direction: column;
  }
}
