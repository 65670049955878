.partner-container {
  padding: 0px 150px 50px 150px;
}
.partner-content {
  text-align: center;
}
.partner-content h1 {
  font-size: 4rem;
  line-height: 1.1;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  color: #273253;
  letter-spacing: 1px;
}
.partners-icon-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  gap: 60px;
}
.partner-icon-section {
  display: flex;
  justify-content: space-evenly;
}

.partner-icon {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-top: 20px;
}
.partner-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 60px;
}
.rfm-marquee {
  margin-right: -146px;
  flex: 0 0 auto;
  min-width: var(--min-width);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
}
.partner-iconss{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 60px;
  display: none;


}
@media (max-width: 1025px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .partner-container {
    padding: 0px 50px 0px 50px;
  }
  .partners-icon-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    gap: 30px;
  }
  .partner-icon {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
  }
  .partner-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
  }
}
@media (max-width: 924px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .partner-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 36px;
    flex-direction: column-reverse;
  }
  .partner-icon {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-top: 20px;
    justify-content: space-between;
  }
  .partners-icon-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    gap: 130px;
  }
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .partner-container {
    padding: 0px 30px 30px 30px;
  }
  .partner-content h1 {
    font-size: 3rem;
    line-height: 1.1;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    margin-top: 20px;
    color: #273253;
    letter-spacing: 1px;
  }
  .icon img {
    width: 80px;
  }
  .partner-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }
  .partners-icon-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    gap: 22px;
    /* width: 100%; */
  }
  .partner-icon {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    /* width: 100%; */
    justify-content: space-evenly;
  }
  .icon-special img {
    width: 216px;
  }
  .marquee{
    display: none;
  }

}
