.icon-container {
  padding: 100px 150px 100px 150px;
}
.icon-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.icons img {
  object-fit: contain;
}
@media (max-width: 1024px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .icons img {
    object-fit: contain;
    width: 210px;
  }
  .icon-main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 50px;
  }
  .icon-container {
    padding: 100px 30px 100px 30px;
  }
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .icon-container {
    padding: 30px 30px 30px 30px;
  }
  .icons img {
    object-fit: contain;
    width: 110px;
  }
  .icons-special-1 img {
    width: 70px;
  }
  .icon-main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}
}
