@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
 
  }
  /* body {
  background: linear-gradient( #141e30,#243b55);
  height: 100vh;
  } */
  html {
      font-size: 62.5%;
      scroll-behavior: smooth;
      scroll-padding-top: 6rem;
      overflow-x: hidden;
    }