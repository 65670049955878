.hero-container {
  padding: 0px 200px 0px 200px;
}
.hero-contents {
  display: flex;
  justify-content: space-between;
}
.hero-content {
  padding-top: 100px;
}
.hero-image {
  padding: 40px;
  position: relative;
}
.hero-image img {
 
  opacity: 0;
  transform: translatex(-30px);
  animation: moveup 0.50s linear forwards;
}
.hero-content h1 {
  font-size: 5rem;
  line-height: 1.1;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  /* margin-top: 20px; */
  color: #273253;
  letter-spacing: 1px;
  animation-delay: 0.10s;
  opacity: 0;
  transform: translatex(-30px);
  animation: moveup 0.50s linear forwards;
}
@keyframes moveup {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.hero-content p {
  margin-top: 20px;

  font-size: 18px;
  font-weight: 500;

  line-height: 1.5;

  color: #000000;
  animation-delay: 0.10s;
  opacity: 0;
  transform: translatex(-30px);
  animation: moveup 0.50s linear forwards;
}
.cont {
  margin-top: 20px;
}
.hero-content img {
  width: 200px;
  opacity: 0;
  animation-delay: 0.12s;
  transform: translatex(-30px);
  animation: moveup 0.50s linear forwards;
}

@media (max-width: 1280px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-container {
    padding: 0px 0px 0px 130px;
  }
}
@media (max-width: 1025px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-content h1 {
    font-size: 4.3rem;
  }
  .hero-content p {
    font-size: 15px;
  }
  .hero-image img {
    width: 290px;
  }
}
@media (max-width: 924px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-content h1 {
    font-size: 3.6rem;
  }
  .hero-content p {
    font-size: 13px;
  }
  .hero-container {
    padding: 0px 0px 0px 72px;
  }
  .hero-image img {
    width: 285px;
  }
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-container {
    padding: 0px 0px 0px 30px;
  }
}

@media (max-width: 640px) {
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .hero-container {
    padding: 0px 30px 0px 30px;
  }
  .hero-content {
    padding-top: 50px;
  }
  .hero-contents {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .hero-content h1 {
    font-size: 32px;
    line-height: 1.1;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    /* margin-top: 20px; */
    color: #273253;
    letter-spacing: 1px;
  }
  br {
    display: none;
  }
  .hero-content p {
    margin-top: 20px;

    font-size: 14px;
    font-weight: 500;

    line-height: 1.5;

    color: #000000;
  }

  .hero-image img {
    position: relative;
    width: 100%;
  }
}
